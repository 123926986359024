import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, Button, Table, Spinner } from "react-bootstrap";
import ModalInvite from "../components/Others/ModalInvite";
import { sendInvitation, getInvitations, resendEmail, cancelInvite } from "../includes/HelperInvite";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Cookies from "universal-cookie";

const cookies = new Cookies();

class Invite extends Component {
  state = {
    showInviteModal: false,
    admin_data: cookies.get("admin_data"),
    isInvitingNewUser: false,
    inviteList: [],
    isLoadingInviteList: false,
  };

  constructor() {
    super();
    this.onFormSubmitInviteUser = this.onFormSubmitInviteUser.bind(this);
  }

  componentDidMount() {
    this.getPendingInvites();
  }

  async getPendingInvites() {
    this.setState({ isLoadingInviteList: true });
    const inviteListResponse = await getInvitations(this.state.admin_data);
    if (inviteListResponse.status === true) {
      this.setState({ inviteList: inviteListResponse.data, isLoadingInviteList: false });
    } else {
      this.setState({ isLoadingInviteList: false });
    }
  }

  async onFormSubmitInviteUser(evt) {
    console.log("Sending new invite");
    evt.preventDefault();

    const teamEmail = evt.target.elements.teamEmail.value;
    const teamName = evt.target.elements.teamName.value;

    if (teamEmail !== "" && teamName !== "") {
      this.setState({ isInvitingNewUser: true });
      // Call API to add trigger
      const response = await sendInvitation(teamEmail, teamName, this.state.admin_data);
      if ("status" in response && response.status === true) {
        this.setState({ inviteList: response.data });
      }

      this.setState({
        showInviteModal: false,
        isInvitingNewUser: false,
      });
    }
  }

  async onHandleResendInvite(invite) {
    this.setState({ resendingEmail: true, resendingEmailTo: invite.user_id });
    await resendEmail(invite.user_id, this.state.admin_data);
    this.setState({ resendingEmail: false });
  }

  async handleCancelInvite(invite) {
    this.setState({ cancellingInvite: true, cancellingInviteFor: invite.user_id });
    const response = await cancelInvite(invite.user_id, this.state.admin_data);
    if ("status" in response && response.status === true) {
      this.setState({ inviteList: response.data });
    }
    this.setState({ cancellingInvite: false });
  }

  async onHandleCancelInvite(invite) {
    confirmAlert({
      title: `Cancel invitation for ${invite.team_name}?`,
      message: "This will remove the associated team and user information from the database",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleCancelInvite(invite),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu history={this.props.history} activeKey="/invite" adminData={this.state.admin_data} />

        <ModalInvite
          showInviteModal={this.state.showInviteModal}
          isInvitingNewUser={this.state.isInvitingNewUser}
          onChangeAudioFile={this.onChangeAudioFile}
          handleClose={() => this.setState({ showInviteModal: false })}
          onFormSubmitInviteUser={this.onFormSubmitInviteUser}
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <h2>Pending Invites</h2>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Button
                className="actionButtonLessPadding"
                style={{ marginLeft: "0px" }}
                variant="success"
                onClick={() => this.setState({ showInviteModal: true })}
              >
                Invite New User
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Email Address</th>
                    <th>Invited On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.isLoadingInviteList === false ? (
                    this.state.inviteList.map((invite, _index) => {
                      return (
                        <tr>
                          <td>{invite.team_name}</td>
                          <td>{invite.user_email}</td>
                          <td>
                            {invite.invited_on !== null ? new Date(invite.invited_on).toISOString().slice(0, 10) : ""}
                          </td>
                          <td>
                            <Button
                              className="actionButtonLessPadding"
                              variant="primary"
                              onClick={() => this.onHandleResendInvite(invite, _index)}
                            >
                              {this.state.resendingEmail === true && this.state.resendingEmailTo === invite.user_id ? (
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{ width: "15px", height: "15px" }}
                                ></Spinner>
                              ) : (
                                "Resending Invitation Email"
                              )}
                            </Button>
                            <Button
                              className="actionButtonLessPadding"
                              style={{ marginLeft: "15px" }}
                              variant="danger"
                              onClick={() => this.onHandleCancelInvite(invite, _index)}
                            >
                              Cancel Invitation
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Invite;
