import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Container, Row, Col } from "react-bootstrap";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const $ = require("jquery");
$.DataTable = require("datatables.net-responsive-dt");

class Scans extends Component {
  state = {
    tableData: [],
    admin_data: cookies.get("admin_data"),
  };

  componentDidMount() {
    console.log(this.el);
    this.$el = $(this.el);
    const user_token = this.state.admin_data.token;

    this.$el.DataTable({
      serverSide: true,
      ordering: true,
      searching: true,
      searchDelay: 2500,
      language: {
        processing: "<div style='padding-top:100px;'><i class='fa fa-refresh'> Loading</i></div>",
      },
      processing: true,
      responsive: true,
      pageLength: 50,
      order: [[0, "desc"]],
      ajax: {
        url: apiBaseUrlLocal + "admin/scans/list",
        data: function (d) {
          d.token = user_token;
        },
      },
      columnDefs: [
        {
          render: function (data, type, row) {
            return row[0];
          },
          title: "#",
          targets: 0,
        },
        {
          render: function (data, type, row) {
            return data + " " + row[2];
          },
          title: "Name",
          targets: 1,
        },
        {
          render: function (data, type, row) {
            return row[3];
          },
          searchable: false,
          title: "ASIN",
          targets: 2,
        },
        {
          render: function (data, type, row) {
            return row[24] === ""
              ? "<span style='color:red;'>REJECT</span>"
              : row[24] === "AMAZON" || row[24] === "WHOLESALE"
              ? row[22]
              : row[24];
          },
          searchable: true,
          title: "WINNER",
          targets: 3,
        },
        {
          render: function (data, type, row) {
            return row[23];
          },
          title: "SCANNED",
          searchable: false,
          targets: 4,
        },
        {
          render: function (data, type, row) {
            return row[4].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          searchable: false,
          title: "SALESRANK",
          targets: 5,
        },
        {
          render: function (data, type, row) {
            return row[5] !== null ? row[5] : 0;
          },
          title: "eSCORE",
          searchable: false,
          targets: 6,
        },
        {
          render: function (data, type, row) {
            return row[6] !== null ? row[6] : 0;
          },
          title: "LowestNew",
          searchable: false,
          targets: 7,
        },
        {
          render: function (data, type, row) {
            return row[7] !== null ? row[7] : 0;
          },
          title: "LowestUsed",
          searchable: false,
          targets: 8,
        },
        {
          render: function (data, type, row) {
            return row[8] !== null ? row[8] : 0;
          },
          title: "NewBB",
          searchable: false,
          targets: 9,
        },
        {
          render: function (data, type, row) {
            return row[9] !== null ? row[9] : 0;
          },
          title: "UsedBB",
          searchable: false,
          targets: 10,
        },
        {
          render: function (data, type, row) {
            return row[10] !== null ? row[10] : 0;
          },
          title: "Amazon",
          searchable: false,
          targets: 11,
        },
        {
          render: function (data, type, row) {
            return row[11] !== null ? row[11] : 0;
          },
          title: "FBA Target",
          searchable: false,
          targets: 12,
        },
        {
          render: function (data, type, row) {
            return row[12] !== null ? row[12] : 0;
          },
          title: "FBA Est",
          searchable: false,
          targets: 13,
        },
        {
          render: function (data, type, row) {
            return row[15] === 1 ? "YES" : "NO";
          },
          title: "FBA Decision",
          searchable: false,
          targets: 14,
        },
        {
          render: function (data, type, row) {
            return row[13] !== null ? row[13] : 0;
          },
          title: "MF Target",
          searchable: false,
          targets: 15,
        },
        {
          render: function (data, type, row) {
            return row[14] !== null ? row[14] : 0;
          },
          title: "MF Est",
          searchable: false,
          targets: 16,
        },
        {
          render: function (data, type, row) {
            return row[16] === 1 ? "YES" : "NO";
          },
          title: "MF Decision",
          searchable: false,
          targets: 17,
        },
        {
          render: function (data, type, row) {
            return row[17];
          },
          title: "SBYB",
          searchable: false,
          targets: 18,
        },
        {
          render: function (data, type, row) {
            return row[18];
          },
          title: "RMS",
          searchable: false,
          targets: 19,
        },
        {
          render: function (data, type, row) {
            return row[19];
          },
          title: "BS",
          searchable: false,
          targets: 20,
        },
        {
          render: function (data, type, row) {
            return row[20];
          },
          title: "SB",
          searchable: false,
          targets: 21,
        },
        {
          render: function (data, type, row) {
            return row[21];
          },
          title: "ZIFFIT",
          searchable: false,
          targets: 22,
        },
        {
          render: function (data, type, row) {
            return row[25];
          },
          title: "SHIPMENT",
          searchable: true,
          targets: 23,
        },
      ],

      scroller: {
        loadingIndicator: true,
      },
    });

    // setInterval(function () {
    //   console.log(`Reloading table ...`);
    //   table.ajax.reload(null, false); // user paging is not reset on reload
    // }, 5000);
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu
          history={this.props.history}
          userData={this.state.admin_data}
          activeKey="/scans"
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        <Container fluid={true}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <table className="display responsive nowrap" width="100%" ref={(el) => (this.el = el)}>
                <thead></thead>
                <tbody></tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Scans;
